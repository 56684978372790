<template lang="pug">
  div
    TheHeader
    v-layout.auth_social(align-center, justify-center, column, fill-height)
      section
        .auth_social__title {{ $t('messages.error.socialSignInEmailNotVerified') }}
        p {{ $t('messages.error.socialSignInEmailNotVerifiedDesc') }}
        //- v-layout(column, align-start)
        //-   router-link.main_link(to="/settings/security") {{ $t('CTAs.secureAccount') }}
        //-     SvgArrowRight.main_link_img
        //-   router-link.main_link(to="/settings/verification") {{ $t('CTAs.unlockFeaturesByVerifyingAccount') }}
        //-     SvgArrowRight.main_link_img
</template>

<script>
import SvgArrowRight from '@/components/svg/SvgArrowRight';
import TheHeader from '@/components/TheHeader';

export default {
  components: { SvgArrowRight, TheHeader }
};
</script>

<style scoped lang="scss">
  @import '../assets/scss/variables';

  .auth_social {
    max-width: 550px;
    min-height: 100vh;
    padding: 0 10px;
    margin: auto;
    text-align: left;

    section {
      position: relative;
      padding-left: 80px;
      width: 100%;
    }

    &__title,
    p {
      margin-bottom: 40px;
      font-weight: bold;
      font-size: 52px;
      line-height: 1;
      color: $light-theme-primary-text;
      text-align: left;

      .svg_check {
        position: absolute;
        left: 0px;

        ::v-deep path { fill: $green !important; }
      }
      @media screen and (max-width: 400px) { font-size: 28px; }
    }

    p {
      font-size: 20px;
      line-height: 32px;
      font-weight: 500;
    }

    .main_link {
      margin-top: 15px;
      white-space: nowrap;
      &:first-of-type { margin-top: auto; }
    }
  }
</style>
